import React from "react"
import { Link } from "@reach/router"

import Layout from "../components/Layaout"
import { Container } from "react-bootstrap"


const SecondPage = () => (
  
  <Layout>
    <body style={{minHeight: "100vh"}}>
    <Container style={{ maxWidth: `800px`, display: "fex", justifyContent: "center", alignItems: "center", padding: "3rem"}}>
        <h2>Gracias por registrarte</h2>
    <p>Te enviaremos un email de bienvenida con algunas instrucciones</p>
    <p><Link to="/app/acceso">Accede</Link> con tus credenciales para ver los datos y los informes. ¡Disfruta del contenido!</p>
    </Container>
    </body>
  </Layout>
  
)

export default SecondPage
